import {Col, Row} from 'antd';
import {TFunction, withTranslation} from 'react-i18next';
import {SvgIcon} from '../../common/SvgIcon';
import Container from '../../common/Container';

import i18n from 'i18next';
import {Chat, Extra, FooterContainer, FooterSection, Language, LogoContainer, NavLink, Para,} from './styles';

interface SocialLinkProps {
    href: string;
    src: string;
}

const Footer = ({t}: { t: TFunction }) => {
    const handleChange = (language: string) => {
        i18n.changeLanguage(language);
    };

    const SocialLink = ({href, src}: SocialLinkProps) => {
        return (
            <a
                href={href}
                target="_blank"
                rel="noopener noreferrer"
                key={src}
                aria-label={src}
            >
                <SvgIcon src={src} width="25px" height="25px"/>
            </a>
        );
    };

    return (
        <>
            <FooterSection>
                <Container>
                    <Row justify="space-between">
                        <Col lg={10} md={10} sm={12} xs={12}>
                            <Language>{t('Address')}</Language>
                            <Para>4 Rue Colonel Arnaud Beltrame</Para>
                            <Para>69008 - Lyon</Para>
                            <Para>France </Para>
                        </Col>

                        <Col lg={10} md={10} sm={12} xs={12}>
                            <Language>{t('Contact')}</Language>
                            {/*<Large to="/">{t('Vous avez une question')}</Large>*/}
                            <Para>
                                {t(`Vous avez une question ?`)}
                            </Para>
                            <a href="mailto:contact@ferlo.tech">
                                <Chat>{t(`Ecrivez-nous`)}</Chat>
                            </a>
                        </Col>
                        {/*<Col lg={8} md={8} sm={12} xs={12}>*/}
                        {/*    <Title>{t('Policy')}</Title>*/}
                        {/*    <Large to="/">{t('Application Security')}</Large>*/}
                        {/*    <Large to="/">{t('Software Principles')}</Large>*/}
                        {/*</Col>*/}
                        {/*<Col lg={6} md={6} sm={12} xs={12}>*/}
                        {/*    <Empty/>*/}
                        {/*    <Large to="/">{t('Support Center')}</Large>*/}
                        {/*    <Large to="/">{t('Customer Support')}</Large>*/}
                        {/*</Col>*/}
                    </Row>
                    <Row justify="space-between">

                        {/*<Col lg={8} md={8} sm={12} xs={12}>*/}
                        {/*    <Title>{t('Company')}</Title>*/}
                        {/*    <Large to="/">{t('About')}</Large>*/}
                        {/*    <Large to="/">{t('Blog')}</Large>*/}
                        {/*    <Large to="/">{t('Press')}</Large>*/}
                        {/*    <Large to="/">{t('Careers & Culture')}</Large>*/}
                        {/*</Col>*/}
                        {/*<Col lg={6} md={6} sm={12} xs={12}>*/}
                        {/*    <Label htmlFor="select-lang">{t('Language')}</Label>*/}
                        {/*    <LanguageSwitchContainer>*/}
                        {/*        <LanguageSwitch onClick={() => handleChange('en')}>*/}
                        {/*            <SvgIcon*/}
                        {/*                src="united-states.svg"*/}
                        {/*                aria-label="homepage"*/}
                        {/*                width="30px"*/}
                        {/*                height="30px"*/}
                        {/*            />*/}
                        {/*        </LanguageSwitch>*/}
                        {/*        <LanguageSwitch onClick={() => handleChange('es')}>*/}
                        {/*            <SvgIcon*/}
                        {/*                src="spain.svg"*/}
                        {/*                aria-label="homepage"*/}
                        {/*                width="30px"*/}
                        {/*                height="30px"*/}
                        {/*            />*/}
                        {/*        </LanguageSwitch>*/}
                        {/*    </LanguageSwitchContainer>*/}
                        {/*</Col>*/}
                    </Row>
                    <Row justify="center">
                        <Col lg={{span: 18, offset: 6}} md={{span: 18, offset: 6}} sm={{span: 18, offset: 6}}
                             xs={{span: 18, offset: 6}}>
                            <Para>🚀 Propulsé par
                                <img src={`/img/icons/logo-ferlo.png`} alt="Ferlo Technology Logo"
                                     style={{width: '30px', margin: '5px', verticalAlign: 'middle'}}/>
                                Ferlo Technology (contact@ferlo.tech)</Para>
                        </Col>
                    </Row>
                </Container>
            </FooterSection>
            <Extra>
                <Container border={true}>
                    <Row
                        justify="space-between"
                        align="middle"
                        style={{paddingTop: '1rem'}}
                    >
                        <NavLink to="/">
                            <LogoContainer>
                                <SvgIcon
                                    src="open.svg"
                                    aria-label="homepage"
                                    width="202px"
                                    height="128px"
                                />
                            </LogoContainer>
                        </NavLink>
                        <FooterContainer>
                            {/*<SocialLink*/}
                            {/*    href="https://github.com/Adrinlol/create-react-app-adrinlol"*/}
                            {/*    src="github.svg"*/}
                            {/*/>*/}
                            <SocialLink
                                href="https://twitter.com/ferlotechnology"
                                src="twitter.svg"
                            />
                            <SocialLink
                                href="https://www.linkedin.com/in/ferlotechnology/"
                                src="linkedin.svg"
                            />
                            <SocialLink
                                href="https://instagram.com/@ferlotechnology/"
                                src="medium.svg"
                            />
                            {/*<a*/}
                            {/*    href="https://ko-fi.com/Y8Y7H8BNJ"*/}
                            {/*    target="_blank"*/}
                            {/*    rel="noopener noreferrer"*/}
                            {/*>*/}
                            {/*    <img*/}
                            {/*        height="36"*/}
                            {/*        style={{border: 0, height: 36}}*/}
                            {/*        src="https://storage.ko-fi.com/cdn/kofi3.png?v=3"*/}
                            {/*        alt="Buy Me a Coffee at ko-fi.com"*/}
                            {/*    />*/}
                            {/*</a>*/}
                        </FooterContainer>
                    </Row>
                </Container>
            </Extra>
        </>
    );
};

export default withTranslation()(Footer);
