import {useState} from 'react';
import {Col, Drawer, Row} from 'antd';
import {TFunction, withTranslation} from 'react-i18next';
import Container from '../../common/Container';
import {SvgIcon} from '../../common/SvgIcon';
import {
    Burger,
    CustomNavLinkSmall,
    HeaderSection,
    Label,
    LogoContainer,
    Menu,
    NotHidden,
    Outline,
    Span,
} from './styles';

const Header = ({t}: { t: TFunction }) => {
    const [visible, setVisibility] = useState(false);

    const toggleButton = () => {
        setVisibility(!visible);
    };

    const MenuItem = () => {
        const scrollTo = (id: string) => {
            const element = document.getElementById(id) as HTMLDivElement;
            element.scrollIntoView({
                behavior: 'smooth',
            });
            setVisibility(false);
        };
        return (
            <>
                <CustomNavLinkSmall onClick={() => scrollTo('about')}>
                    <Span>{t('Accueil')}</Span>
                </CustomNavLinkSmall>
                <CustomNavLinkSmall onClick={() => scrollTo('mission')}>
                    <Span>{t('Fonctionnalités')}</Span>
                </CustomNavLinkSmall>
                <CustomNavLinkSmall onClick={() => scrollTo('product')}>
                    <Span>{t('Prix')}</Span>
                </CustomNavLinkSmall>
                <CustomNavLinkSmall onClick={() => scrollTo('contact')}>
                    <Span>{t('Contact')}</Span>
                </CustomNavLinkSmall>
            </>
        );
    };

    return (
        <HeaderSection>
            <Container>
                <Row justify="space-between">
                    <LogoContainer to="/" aria-label="homepage">
                        <SvgIcon src="open.svg" width="250px" height="175px"/>
                    </LogoContainer>
                    <NotHidden>
                        <MenuItem/>
                    </NotHidden>
                    <Burger onClick={toggleButton}>
                        <Outline/>
                    </Burger>
                </Row>
                <Drawer closable={false} open={visible} onClose={toggleButton}>
                    <Col style={{marginBottom: '2.5rem'}}>
                        <Label onClick={toggleButton}>
                            <Col span={12}>
                                <Menu>Menu</Menu>
                            </Col>
                            <Col span={12}>
                                <Outline/>
                            </Col>
                        </Label>
                    </Col>
                    <MenuItem/>
                </Drawer>
            </Container>
        </HeaderSection>
    );
};

export default withTranslation()(Header);
